<template>
  <div>
    <div class="bg bg1">
      <div class="btns-wrapper">
        <button class="btn" @click="handleBtnDetectionClick">
          免费检测 <i class="icon iconfont iconarrowRight"></i>
        </button>
        <button class="btn" @click="handleBtnContactUsClick">联系我们</button>
      </div>
    </div>
    <div class="bg bg2"></div>
    <div class="bg bg3"></div>
    <div class="bg bg4"></div>
    <ContactDialog ref="contactDialog"></ContactDialog>
  </div>
</template>

<script>
import ContactDialog from "./ContactDialog";
export default {
  components: {
    ContactDialog,
  },
  data() {
    return {};
  },
  methods: {
    handleBtnContactUsClick() {
      this.$refs["contactDialog"].dialogVisible = true;
    },
    handleBtnDetectionClick() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="stylus" scoped>
.btn-rounded {
  width: 120px;
  height: 36px;
  border-radius: 16px;
}

.bg {
  background-size: 100%;
  background-origin: center;
  width: 100%;
  background-repeat: no-repeat;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .bg1 {

    .btns-wrapper {

      .btn {
        height: 20vw;
        line-height: 20vw;
        font-size: 12px;
        .icon{
          font-size: 12px;
        }
      }
    }
  }
}

.bg1 {
  background-image: url('~@/assets/free/01@2x.jpg');
  padding-bottom: 72%;

  .btns-wrapper {
    top: 45%;
    position: absolute;
    left: 18.5%;

    .btn {
      margin-right: 1vw;
      height: 3.2vw;
      line-height: 3.2vw;
      padding: 0 3vw;
      font-size: 1vw;
      background: #fff;
      border-radius: 5px;
      color: #2F54EB;
      min-height: 25px;
      .icon{
          font-size: 1vw;
        }
    }
  }
}

.bg2 {
  background-image: url('~@/assets/free/02@2x.jpg');
  padding-bottom: 46.3%;
}

.bg3 {
  background-image: url('~@/assets/free/03@2x.jpg');
  padding-bottom: 46%;
}

.bg4 {
  background-image: url('~@/assets/free/04@2x.jpg');
  padding-bottom: 101%;
}
</style>